/** @jsxImportSource theme-ui */

import { useEffect, useState } from "react";
import { Button, Container, Grid, Input, Link, Spinner } from "theme-ui";
import { useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { verifyJwt } from "../../checkJWT.js";
import Cookies from "js-cookie";

import { Editor as ClassicEditor } from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import axios from "axios";

const config = {
    toolbar: {
        items: [
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "subscript",
            "superscript",
            "|",
            "highlight",
            "bulletedList",
            "numberedList",
            "|",
            "fontColor",
            "fontSize",
            "fontFamily",
            "|",
            "findAndReplace",
            "|",
            "code",
            "alignment",
            "horizontalLine",
            "specialCharacters",
            "|",
            "insertTable",
            "undo",
            "redo",
            "selectAll",
        ],
    },
    language: "en",
    table: {
        contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    },
    fontFamily: {
        options: [
            "default",
            "Arial, Helvetica, sans-serif",
            "Courier New, Courier, monospace",
            "Georgia, serif",
            "Lucida Sans Unicode, Lucida Grande, sans-serif",
            "Tahoma, Geneva, sans-serif",
            "Times New Roman, Times, serif",
            "Trebuchet MS, Helvetica, sans-serif",
            "Verdana, Geneva, sans-serif",
        ],
    },
    fontSize: {
        options: [9, 11, 13, "default", 17, 19, 21],
    },
};

const Index = () => {
    const [loading, setLoading] = useState(true);
    const [isAuth, setIsAuth] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [data, setData] = useState("");
    const [allBlogs, setAllBlogs] = useState([]);

    const location = useLocation();
    const navigate = useNavigate();

    const addBlog = async () => {
        let title = `<h1>${data.substring(
            data.indexOf("<h1>") + 4,
            data.lastIndexOf("</h1>")
        )}</h1>`;

        let body = data.split("</h1>")[1];

        try {
            await axios
                .post(`${process.env.REACT_APP_SERVER}/add-blog`, {
                    title: title,
                    authorId: Cookies.get("_id"),
                    text: body,
                    token: Cookies.get("token"),
                })
                .then(() => setData(""));
        } catch (err) {
            console.log(err);
        }
        getAllBlogs();
    };

    const getAllBlogs = async () => {
        try {
            const blogs = await axios.get(
                `${process.env.REACT_APP_SERVER}/blogs`
            );
            setAllBlogs(blogs.data.blogs);
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    };

    useEffect(() => {
        try {
            let token = Cookies.get("token") || location.state.authToken;
            if (token) {
                setIsAuth(verifyJwt(token));
            } else {
                setIsAuth(false);
            }
            getAllBlogs();
            console.log(allBlogs);
        } catch (err) {}
    }, []);

    return (
        <motion.main
            className="main__container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
        >
            <Container
                sx={{
                    padding: "1rem 1rem",
                }}
            >
                {!isAuth ? (
                    <div className="flex-center-hori">
                        <Link
                            className={"fw"}
                            href="/login"
                            sx={{
                                marginRight: 2.5,
                            }}
                        >
                            <Button className={"fw"}>Login</Button>
                        </Link>

                        <Link
                            className={"fw"}
                            sx={{
                                marginLeft: 2.5,
                            }}
                            variant="secondary"
                            href="/register"
                        >
                            <Button
                                className={"fw"}
                                sx={{
                                    marginLeft: 2.5,
                                }}
                                variant="secondary"
                            >
                                Signup
                            </Button>
                        </Link>
                    </div>
                ) : (
                    <div className="flex-center-hori">
                        <Button
                            variant={"primary"}
                            className="fw"
                            style={{
                                marginRight: 2.5,
                            }}
                            onClick={() => {
                                Cookies.remove("token");
                                window.location.reload();
                            }}
                        >
                            Logout
                        </Button>
                        <Button
                            variant={"secondary"}
                            className="fw"
                            style={{
                                marginLeft: 2.5,
                            }}
                            onClick={() => setShowAdd(!showAdd)}
                        >
                            Add your blog
                        </Button>
                    </div>
                )}

                {showAdd && (
                    <form
                        sx={{ marginY: "5px" }}
                        onSubmit={(e) => e.preventDefault()}
                    >
                        <CKEditor
                            editor={ClassicEditor}
                            config={config}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setData(data);
                            }}
                        />
                        <Button
                            onClick={() => {
                                addBlog();
                                setData("");
                            }}
                            type="submit"
                            variant="primary"
                            className="fw"
                            sx={{ marginTop: "5px" }}
                        >
                            PostIt.
                        </Button>
                    </form>
                )}
                <div id="editor"></div>
                <h1 className="tac">Blog</h1>

                {loading ? (
                    <div className="full-screen">
                        <Spinner />
                    </div>
                ) : (
                    <Grid gap={4} columns={[1, 1, 2]}>
                        {allBlogs.map((blog) => (
                            <div
                                sx={{
                                    border: "1px solid #000",
                                    borderRadius: "5px",
                                }}
                                className="blog"
                            >
                                <h1
                                    sx={{ textAlign: "center" }}
                                    dangerouslySetInnerHTML={{
                                        __html: blog.title,
                                    }}
                                />
                                <p sx={{ textAlign: "center" }}>
                                    Posted by: <b>{blog.authorName}</b>
                                </p>
                                <Button
                                    onClick={() => {
                                        navigate(`/blog/${blog._id}`);
                                    }}
                                    m={1}
                                >
                                    Read More.
                                </Button>
                            </div>
                        ))}
                    </Grid>
                )}
            </Container>
        </motion.main>
    );
};

export default Index;
