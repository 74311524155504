/** @jsxImportSource theme-ui */

import React, { useEffect, useState } from "react";
import { Button, Container, Divider } from "theme-ui";
import { useParams } from "react-router-dom";
import { BiUpvote } from "react-icons/bi";
import Cookies from "js-cookie";

import axios from "axios";

const Index = () => {
    const { id } = useParams();

    const [blog, setBlog] = useState({});
    const [loading, setLoading] = useState(true);

    const getBlog = async () => {
        setLoading(true);
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_SERVER}/blogs/${id}`
            );
            setBlog(res.data.blogs);
        } catch (err) {
            console.log(err);
        }
    };

    const likeBlog = async () => {
        let u_id = Cookies.get("_id");
        let token = Cookies.get("token");

        try {
            await axios
                .post(`${process.env.REACT_APP_SERVER}/like-blog`, {
                    id: id,
                    token: token,
                    u_id: u_id,
                })
                .then((res) => {
                    setBlog(res.data.blogs);
                    getBlog();
                    console.log(res.data.blogs);
                });
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getBlog();
        setLoading(false);
    }, []);

    return (
        <Container m={4}>
            <h1 dangerouslySetInnerHTML={{ __html: blog?.title }}></h1>
            <p>Posted by: {blog?.authorName}</p>
            <Divider />
            <p dangerouslySetInnerHTML={{ __html: blog?.text }}></p>

            <span
                onClick={likeBlog}
                sx={{
                    background: "primary",
                    color: "#fff",
                    padding: "10px",
                    cursor: "pointer",
                }}
            >
                <BiUpvote />
                &nbsp;{blog?.n_likes}
            </span>
        </Container>
    );
};

export default Index;
