/** @jsxImportSource theme-ui */

import React, { useRef, useState } from "react";
import "./navbar.css";
import { Link } from "react-router-dom";
import { MenuButton } from "theme-ui";

function Index() {
    const navRef = useRef();
    const [isOpen, setIsOpen] = useState(false);

    const toggleOffCanvas = () => {
        const nav = document.getElementById("navbar");
        setIsOpen(!isOpen);
        if (isOpen) {
            setTimeout(() => {
                nav.style.display = "none";
            }, 0);
        } else {
            nav.style.display = "flex";
        }
    };

    return (
        <>
            <div
                onClick={toggleOffCanvas}
                sx={{
                    background: "primary",
                    color: "background",
                }}
                className={"hamburger mv-0 pv-0 ph-0 mh-0"}
            >
                <MenuButton
                    style={{ cursor: "pointer" }}
                    aria-label="Toggle Menu"
                />
                <h4>GirlUp AI</h4>
            </div>
            <nav id={"navbar"} ref={navRef}>
                <Link
                    onClick={toggleOffCanvas}
                    className={"heading nav-item"}
                    sx={{ color: "primary" }}
                    to={"/"}
                >
                    Home
                </Link>
                <Link
                    onClick={toggleOffCanvas}
                    className={"heading nav-item"}
                    sx={{ color: "primary" }}
                    to={"/courses"}
                >
                    Courses
                </Link>
                <Link
                    onClick={toggleOffCanvas}
                    className={"heading nav-item"}
                    sx={{ color: "primary" }}
                    to={"/programs"}
                >
                    Internship Programs 
                </Link>
                <Link
                    onClick={toggleOffCanvas}
                    className={"heading nav-item"}
                    sx={{ color: "primary" }}
                    to={"/meet-the-team"}
                >
                    Meet the team
                </Link>
                <Link
                    onClick={toggleOffCanvas}
                    className={"heading nav-item"}
                    sx={{ color: "primary" }}
                    to={"/contact-us"}
                >
                    Contact us
                </Link>
                <Link
                    onClick={toggleOffCanvas}
                    className={"heading nav-item"}
                    sx={{ color: "primary" }}
                    to={"/blog"}
                >
                    Blogs
                </Link>
                <span
                    className={"heading nav-item"}
                    sx={{ color: "muted" }}
                    to={"/donate"}
                >
                    Donate
                </span>

            </nav>
        </>
    );
}

export default Index;
