/** @jsxImportSource theme-ui */

import React, { useState } from 'react';
import { Button, Container, Divider, Grid } from 'theme-ui';
import { IoTimeOutline } from 'react-icons/io5';
import Modal from '../../shared/Modal';
import { motion } from 'framer-motion';

const Courses = () => {

    const courses = [
        {
            name: "Learn Python Programming",
			authors: ["Aryan Nath"],
			description: "Welcome to Introduction to Programming Using Python, a course designed by Aryan Nath for Girl Up AI. Through the course, you will be able to get a good understanding and grip on the basics of Python Programming. The course is divided into three modules, all of which entail a series of short videos that will explain the basics of every concept.\nHappy Learning!",
            difficulties: [
                {
                    difficulty: "Beginner",
                    courseLink: "https://www.youtube.com/watch?v=6Qli38EfXZs"
                },
                {
                    difficulty: "Intermediate",
                }
            ],
       },
       {
            name: "Learn Java Programming",
			authors: ["FreeCodeCamp", "Simplilearn"],
			description: "Learn the Java programming language in this complete beginner's course. You will learn all the main features of Java (version 17) and how to use Java for your own projects.",
            difficulties: [
                {
                    difficulty: "Beginner",
                    courseLink: "https://www.youtube.com/watch?v=A74TOX803D0"
                },
				{
					difficulty: "Advanced",
					courseLink: "https://www.youtube.com/watch?v=Ae-r8hsbPUo"
				}
            ],
       }
    ]

	return (
		<motion.main
			className='main__container'
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.5 }}
		>
			<Container p={1}>
				<h1
					className='tac'
					sx={{ color: 'primary' }}
				>
					Courses
				</h1>

				<section className={'ph-5'}>
					<Grid columns={1}>
						{courses.map((item) => (
							<div className={'card'}>
								<h5
									className='tac'
									sx={{ color: 'primary' }}
								>
									{item.name}
								</h5>
								<p className="tac mv-0">
									By { item.authors ? item.authors.join(", ") : "Unknown Author"}
								</p>
								<Divider />
								<p className="tac">
									{item.description || "No Description Available"}
								</p>
								<div style={{
									display: "flex",
									width: "100%",
									justifyContent: "center",
									gap: "0.5rem"
									}}>
									{item.difficulties.map(subItem => (
										<Button
											variant={'primary'}
											
											onClick={()=>{
												if (subItem.courseLink)
													window.open(subItem.courseLink);
										}}
									>
									{ 
										subItem.difficulty + (subItem.courseLink ? "" : " (Coming Soon)")
									}
										</Button>
									))}
								</div>
							</div>
						))}
					</Grid>
				</section>
			</Container>
		</motion.main>
	);
};
export default Courses;
