/** @jsxImportSource theme-ui */

import React from 'react'
import {motion} from "framer-motion";

const Index = () => {
    return (
        <motion.main
            className="main__container"
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.5}}
        >
            <div style={{
                height: 'calc(100vh - 10vh)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <h1 sx={{color: 'primary'}} className="tac">404</h1>
                <p className="tac">The page you're looking for doesn't exist!</p>
            </div>
        </motion.main>
    )
}
export default Index
