import jwt_decode from 'jwt-decode';

export const verifyJwt = (token) => {
	let decoded = jwt_decode(token);
	let currDate = new Date();

	if (decoded.exp * 1000 < currDate.getTime()) {
		return false;
	} else {
		return true;
	}
};
