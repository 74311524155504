/** @jsxImportSource theme-ui */

import React from 'react'
import './modal.css'
import {Button, Close, Grid} from "theme-ui";

const Index = ({isOpen, title, desc, onClose}) => {
    if (isOpen) {
        return <div
            className={'modal flex-center-vertical m-0 p-0'}
            style={{
                background: "rgba(0,0,0,0.9)",
                padding: '0 2rem',
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100vh'
            }}
        >
            <h4 className={'tac m-0 p-0'} sx={{color: 'background'}}>{title}</h4>
            <p sx={{color: 'background'}} className={'m-0 p-0'} dangerouslySetInnerHTML={{__html: desc}}></p>
            <Grid columns={2}>
                <Button
                    onClick={() => window.location.href = 'https://api.whatsapp.com/send?phone=919650085432&text=Heyy!%0AI%20want%20to%20enroll%20in%20the%20%22' + title + '%22%20summer%20internship%0AYour%20name%3A%20%0AYour%20email%3A%0AYour%20phone%3A'}
                    className={'fw'}
                    variant={'primary'}>
                    WhatsApp us to enroll!
                </Button>
                <Button onClick={onClose} className={'fw'} variant={'secondary'}>
                    <Close/>
                </Button>
            </Grid>
        </div>
    } else {
        return null;
    }
}
export default Index
