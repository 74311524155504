/** @jsxImportSource theme-ui */

import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Button, Divider, Spinner } from "theme-ui";
import { motion } from "framer-motion";

const Index = () => {
    const [pending, setPending] = useState([]);
    const [blogs, setBlogs] = useState([]);
    const [pLoading, setPLoading] = useState(true);
    const [aLoading, setALoading] = useState(true);

    const getAll = async () => {
        try {
            await axios
                .get(`${process.env.REACT_APP_SERVER}/blogs`)
                .then((res) => {
                    console.log(res.data.blogs.length);
                    if (res.data.blogs.length > 0) {
                        setBlogs(res.data.blogs);
                        setALoading(false);
                    } else {
                        setBlogs([]);
                    }
                });
        } catch (err) {}
    };

    const getPending = async () => {
        setPLoading(true);
        try {
            await axios
                .get(`${process.env.REACT_APP_SERVER}/get-pending`)
                .then((res) => {
                    if (res.data.blogs.length > 0) {
                        setPending(res.data.blogs);
                        setPLoading(false);
                    } else {
                        setPending([]);
                    }
                });
        } catch (err) {}
    };

    const approve = async (id) => {
        try {
            await axios
                .post(`${process.env.REACT_APP_SERVER}/approve`, { id: id })
                .then((res) => {
                    getPending();
                });
        } catch (err) {}
    };

    const deny = async (id) => {
        try {
            await axios
                .post(`${process.env.REACT_APP_SERVER}/reject`, { id: id })
                .then((res) => {
                    getPending();
                    getAll();
                });
        } catch (err) {}
    };

    const remove = async (id) => {
        try {
            await axios
                .post(`${process.env.REACT_APP_SERVER}/remove`, { id: id })
                .then((res) => {
                    getPending();
                    getAll();
                });
        } catch (err) {}
    };

    useEffect(() => {
        getPending();
        getAll();

        setPLoading(false);
        setALoading(false);
    }, []);

    if (Cookies.get("role") == "User") {
        return (
            <motion.main
                className="main__container"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
            >
                <div
                    style={{
                        height: "calc(100vh - 10vh)",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <h1 sx={{ color: "primary" }} className="tac">
                        401 (Unauthorized)
                    </h1>
                    <p className="tac">
                        You are not authorized to view this page!
                    </p>
                </div>
            </motion.main>
        );
    } else {
        return (
            <motion.main
                className="main__container"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
            >
                <div
                    style={{
                        padding: "0 1rem",
                        paddingTop: "1rem",
                    }}
                >
                    <h4>Needs approval</h4>
                    {!pLoading && pending.length != 0 ? (
                        pending.map((blog) => (
                            <div
                                sx={{
                                    border: "1px solid #000",
                                    borderRadius: "5px",
                                }}
                                className="blog"
                            >
                                <h1
                                    sx={{ textAlign: "center" }}
                                    dangerouslySetInnerHTML={{
                                        __html: blog.title,
                                    }}
                                />
                                <p sx={{ textAlign: "center" }}>
                                    Posted by: <b>{blog.authorName}</b>
                                </p>
                                <div
                                    className="flex-center-hori"
                                    style={{
                                        paddingBottom: "0.5rem",
                                    }}
                                >
                                    <Button
                                        onClick={() => {
                                            approve(blog._id);
                                        }}
                                    >
                                        Approve.
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            deny(blog._id);
                                        }}
                                    >
                                        Deny.
                                    </Button>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="full-screen">
                            <Spinner />
                        </div>
                    )}

                    <Divider />

                    <h4>Approved</h4>
                    {!aLoading && blogs.length != 0 ? (
                        blogs.map((blog) => (
                            <div
                                sx={{
                                    border: "1px solid #000",
                                    borderRadius: "5px",
                                }}
                                className="blog"
                            >
                                <h1
                                    sx={{ textAlign: "center" }}
                                    dangerouslySetInnerHTML={{
                                        __html: blog.title,
                                    }}
                                />
                                <p sx={{ textAlign: "center" }}>
                                    Posted by: <b>{blog.authorName}</b>
                                </p>
                                <div
                                    className="flex-center-hori"
                                    style={{
                                        paddingBottom: "0.5rem",
                                    }}
                                >
                                    <Button
                                        onClick={() => {
                                            remove(blog._id);
                                        }}
                                    >
                                        Remove.
                                    </Button>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="full-screen">
                            <Spinner />
                        </div>
                    )}
                </div>
            </motion.main>
        );
    }
};

export default Index;
