/** @jsxImportSource theme-ui */

import React from 'react';
import './MeetTheTeam.css';
import { Badge, Grid } from 'theme-ui';

import krishya from '../../images/krishya-khajanchi.png';
import prayag from '../../images/prayag-jain.png';
import kashviKhajanchi from '../../images/kashvi-khajanchi.jpg';
import kashviSikka from '../../images/kashvi-sikka.jpg';
import { motion } from 'framer-motion';

const MeetTheTeam = () => {
	const team = [
		{
			name: ['Krishya', 'Khajanchi'],
			post: 'Founder (she/her)',
			linkedin: 'https://www.linkedin.com/in/krishyakhajanchi/',
			email: 'krishyakhajanchi@reinvigoratefoundation.org',
			insta: 'https://www.instagram.com/krishya.khajanchi/',
			image: krishya,
			animation: {
				left: true,
			},
			brief: 'Krishya Khajanchi is the founder and CEO of Reinvigorate Foundation. She has been passionate about building a sustained, healthy, and happy environment since her early years.',
		},

		{
			name: ['Kashvi', 'Khajanchi'],
			post: 'Planning | Outreach (she/her)',
			insta: 'https://instagram.com/kashvi.khajanchi/',
			email: 'adityatripathi@reinvigoratefoundation.org',
			image: kashviKhajanchi,
			brief: "The Planning and Outreach Head is responsible for developing and implementing the organization's strategic plan, as well as overseeing all outreach efforts.She has a strong background in planning, strategic development, and outreach.",
		},
		{
			name: ['Kashvi', 'Sikka'],
			post: 'Head - Editorial (she/her)',
			insta: 'https://www.instagram.com/kash__sikk/',
			email: 'adityatripathi@reinvigoratefoundation.org',
			image: kashviSikka,
			brief: 'Kashvi Sikka, a 15 year old who has got a lot to figure out still! She thoroughly enjoys writing, playing the piano, dancing, hosting events, talking to people and learning about things that fascinate her the most. As a teen, she also loves to be a small tiny part of any good cause directed to our society.',
		},
		{
			name: ['Prayag', 'Jain'],
			post: 'Head - Tech | Outreach (he/him)',
			linkedin: 'https://www.linkedin.com/in/prayagjain/',
			insta: 'https://www.instagram.com/prayag_jain2/',
			email: 'prayag.jain@reinvigoratefoundation.org',
			image: prayag,
            brief: "Prayag Jain holds the role of Technical Team Lead at Reinvigorate Foundation. He is deeply interested in open source and free software, leveraging his expertise and dedication to develop impactful software that contribute positively to the world."
		},
	];

	return (
		<motion.main
			className='main__container'
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.5 }}
		>
			<section className={'ph-5'}>
				<h1
					className='tac'
					sx={{ color: 'primary' }}
				>
					Meet the Team
				</h1>

				<p>
					We are a team of passionate and experienced professionals
					who are dedicated to providing our clients with the best
					possible service. We are always looking for new and
					innovative ways to help our clients achieve their goals.
				</p>

				<Grid columns={[1, 2]}>
					{team.map((member) => {
						return (
							<div className='card'>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-around',
										alignItems: 'center',
										paddingBottom: '0.9rem',
									}}
								>
									{member.insta && (
										<Badge
											style={{
												cursor: 'pointer',
												padding: '0.5rem',
												borderRadius: '0.5rem',
											}}
											onClick={() =>
												window.open(
													member.insta,
													'blank',
												)
											}
										>
											Instagram
										</Badge>
									)}
									{member.linkedin && (
										<Badge
											style={{
												cursor: 'pointer',
												padding: '0.5rem',
												borderRadius: '0.5rem',
											}}
											onClick={() =>
												window.open(
													member.linkedin,
													'blank',
												)
											}
										>
											LinkedIn
										</Badge>
									)}
								</div>
								<img
									style={{
										maxWidth: '50%',
										marginLeft: '25%',
									}}
									src={member.image}
									className={'card-img'}
								/>
								<h4 className='m-0 p-0 tac'>
									{member.name[0]}&nbsp;
									<span sx={{ color: 'primary' }}>
										{member.name[1]}
									</span>
								</h4>
								<p className='m-0 p-0 tac'>{member.post}</p>
								<p>{member.brief}</p>
							</div>
						);
					})}
				</Grid>

				<p>
					We are{' '}
					<span
						sx={{ color: 'primary' }}
						className='heading'
					>
						proud
					</span>{' '}
					of our team and the work that we do. We are committed to
					providing our clients with the best possible service. If you
					have any questions, please do not hesitate to contact us.
				</p>
			</section>
		</motion.main>
	);
};
export default MeetTheTeam;
