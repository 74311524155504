export const theme = {
	breakpoints: ['40em', '52em', '64em'],
	space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
	fonts: {
		body: 'ProximaNova, serif',
		heading: 'MuseoSans, serif',
	},
	fontWeights: {
		body: 400,
		heading: 700,
		bold: 700,
	},
	lineHeights: {
		body: 1.5,
		heading: 1.125,
	},
	colors: {
		text: '#000',
		background: '#FFF',
		primary: '#d13350',
		secondary: '#00a1e2',
		muted: 'rgba(100,100,100,0.5)',
	},
	text: {
		heading: {
			fontFamily: 'heading',
			lineHeight: 'heading',
			fontWeight: 'heading',
		},
	},
	forms: {
		labels: {
			textTransform: 'uppercase',
		},
	},
	styles: {
		root: {
			color: 'text',
			fontFamily: 'body',
			lineHeight: 'body',
			fontWeight: 'body',
		},
		h1: {
			variant: 'text.heading',
		},
		h2: {
			variant: 'text.heading',
		},
		h3: {
			variant: 'text.heading',
		},
		h4: {
			variant: 'text.heading',
		},
		h5: {
			variant: 'text.heading',
		},
		h6: {
			variant: 'text.heading',
		},
		pre: {
			fontFamily: 'monospace',
			overflowX: 'auto',
			code: {
				color: 'inherit',
			},
		},
		code: {
			fontFamily: 'monospace',
			fontSize: 'inherit',
		},
		table: {
			width: '100%',
			borderCollapse: 'separate',
			borderSpacing: 0,
		},
		th: {
			textAlign: 'left',
			borderBottomStyle: 'solid',
		},
		td: {
			textAlign: 'left',
			borderBottomStyle: 'solid',
		},
	},
	buttons: {
		primary: {
			cursor: 'primary',
			fontFamily: 'body',
			border: '1px solid #fff',
			bg: 'primary',
			'&:hover': {
				bg: 'secondary',
				borderColor: '#000',
			},
		},
		secondary: {
			border: '1px solid #fff',
			fontFamily: 'body',
			cursor: 'primary',
			color: 'background',
			bg: 'secondary',
			'&:hover': {
				bg: 'background',
				color: 'text',
				borderColor: '#000',
			},
		},
	},
};
