/** @jsxImportSource theme-ui */
import React from 'react'
import {Container, IconButton} from "theme-ui";
import './contact.css'
import {IoCallOutline, IoLogoInstagram, IoMailOutline} from 'react-icons/io5'
import {motion} from "framer-motion";

const Contact = () => {
    const openLink = (link) => {
        window.open(link, "blank")
    }

    return (
        <motion.main
            className="main__container"
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.5}}
        >
            <div style={{
                height: "calc(100vh - 10vh)",
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }} className={'ph-5'}>
                <h2 sx={{color: 'primary'}} className={'tac m-0 p-0'}>Contact Us!</h2>
                <p onClick={() => openLink("tel:+919650085432")} className={'link tac'}><IoCallOutline/>&nbsp;+91 96500
                    85432</p>
                <p onClick={() => openLink("mailto:contact@reinvigoratefoundation.org")} className={'link tac'}>
                    <IoMailOutline/>&nbsp;contact@reinvigoratefoundation.org</p>
                <p onClick={() => openLink("https://www.instagram.com/reinvigoratefoundation/")}
                   className={'link tac'}><IoLogoInstagram/>&nbsp;Reinvigorate Foundation</p>
                <p onClick={() => openLink("https://www.instagram.com/expresstoliberate/")} className={'link tac'}>
                    <IoLogoInstagram/>&nbsp;Express to Liberate</p>
            </div>
        </motion.main>
    )
}
export default Contact
