/** @jsxImportSource theme-ui */

import React from 'react';
import './home.css';
import { Button, Container, Grid } from 'theme-ui';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const HomeScreen = () => {
	return (
		<motion.main
			className='main__container'
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.5 }}
		>
			<div className='landing'>
				<h1 className={'mv-0'}>Girl Up AI</h1>
				<p>
					For the betterment of{' '}
					<span
						sx={{
							color: 'primary',
						}}
					>
						HUMANKIND
					</span>
				</p>
				<Button
					style={{ cursor: 'pointer !important' }}
					variant={'primary'}
				>
					<a href='#about'>Get to know us</a>
				</Button>
			</div>

			<section
				className={'ph-5'}
				id={'about'}
			>
				<h3
					sx={{ color: 'primary' }}
					className={'tac'}
				>
					About Us
				</h3>
				<Grid
					columns={[1, 2]}
					gap={1}
				>
					<div className={'card'}>
						<h4
							sx={{ color: 'primary' }}
							className={'tac'}
						>
							Vision
						</h4>
						<p>
							Girl Up AI is an initiative working on combating the
							gender bias associated with the world of AI.
						</p>
						<p>
							Today's world has become cohesive with AI as
							technology has become an inevitable resource.
						</p>
						<p>
							Unfortunately, the ratio of females to males in
							regards to technological pteracy is very off. The
							total participation of women is as low as 22%,
							compared to the remaining 78% which is upheld by
							men.
						</p>
					</div>
					<div className='card'>
						<h4
							sx={{ color: 'primary' }}
							className={'tac'}
						>
							Mission
						</h4>
						<p>
							Girl Up AI's mission is to combat the gap which has
							been built between AI and young girls due to several
							reasons, some being gender bias, lack of resources
							and lack of pteracy.
						</p>
						<p>
							We wish to see a world where AI is a tool accessible
							to every member of our generation as we wish to see
							change and be it.
						</p>
						<p>
							To achieve this, we provide programs, specific to
							domains that fall under technology.
						</p>
						<p>
							We make these programs highly accessible and
							engaging so that the gap between you and AI is
							bridged.
						</p>
					</div>
				</Grid>
			</section>

			<section className={'about'}>
				<div>
					<div className='left'></div>
					<h2
						className={'tac mv-0 pv-0'}
						sx={{ color: 'primary' }}
					>
						Ready to bring a change?
					</h2>
					<div className='right'></div>
					<Link to={'/contact-us'}>
						<Button variant={'secondary'}>Change!</Button>
					</Link>
				</div>
			</section>

			<section className={'ph-5'}>
				<h1
					sx={{ color: 'primary' }}
					className={'tac'}
				>
					Our Values
				</h1>
				<Grid
					columns={[1, 2]}
					gap={1}
				>
					<div className='card'>
						<h4
							sx={{ color: 'primary' }}
							className={'tac'}
						>
							Change Makers
						</h4>
						<p>
							We at Girl Up AI are members of the youth who wish
							to see flourishing growth in the arena of
							technology.
						</p>
						<p>
							We work relentlessly to be the change we wish to
							see.
						</p>
					</div>
					<div className='card'>
						<h4
							sx={{ color: 'primary' }}
							className={'tac'}
						>
							Gender Equality
						</h4>
						<p>
							Girl Up AI works to bridge the gap that has been
							created between girls and technology.{' '}
						</p>
						<p>
							The world of AI is a tool which has got nothing to
							do with the gender of its user. So, the practice of
							keeping girls away from it has to be put to an end.
						</p>
					</div>
				</Grid>
			</section>
		</motion.main>
	);
};
export default HomeScreen;
