/** @jsxImportSource theme-ui */

import "./App.css";
import { ThemeProvider } from "theme-ui";
import { theme } from "./theme";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
import Navbar from "./shared/Navbar";
import Programs from "./screens/Programs";
import Courses from "./screens/Courses";
import MeetTheTeam from "./screens/MeetTheTeam";
import Contact from "./screens/Contact";
import PageNotFound from "./screens/PageNotFound";
import Blog from "./screens/Blog";
import Login from "./screens/Login";
import Signup from "./screens/Signup";
import OneBlog from "./screens/OneBlog";
import Admin from "./screens/Admin";

import { AnimatePresence } from "framer-motion";

function App() {
    // const location = useLocation();
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Navbar />
                <AnimatePresence>
                    <Routes
                        location={window.location}
                        key={window.location.pathname}
                    >
                        <Route path={"/"} element={<HomeScreen />} />
                        <Route path={"/programs"} element={<Programs />} />
                        <Route path={"/courses"} element={<Courses />} />
                        <Route path={"/contact-us"} element={<Contact />} />
                        <Route
                            path={"/meet-the-team"}
                            element={<MeetTheTeam />}
                        />
                        <Route path={"/blog"} element={<Blog />} />
                        <Route path={"/login"} element={<Login />} />
                        <Route path={"/register"} element={<Signup />} />
                        <Route path={"/blog/:id"} element={<OneBlog />} />
                        <Route path={"admin"} element={<Admin />} />
                        <Route path={"*"} element={<PageNotFound />} />
                    </Routes>
                </AnimatePresence>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
