/** @jsxImportSource theme-ui */

import React, { useState } from "react";
import { motion } from "framer-motion";
import {
    Box,
    Button,
    Checkbox,
    Input,
    Label,
    Message,
    Spinner,
} from "theme-ui";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import validator from "validator";

const Index = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [msg, setMsg] = useState("");
    const [showPassword, setShowPassword] = useState(true);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);

    const navigate = useNavigate();

    const login = async () => {
        setLoading(true);
        try {
            const res = axios
                .post(`${process.env.REACT_APP_SERVER}/login`, {
                    email,
                    password,
                })
                .then((res) => {
                    console.log(res);
                    let authToken = res.data.msg.token;

                    Cookies.set("token", authToken);
                    Cookies.set("_id", res.data.msg._id);
                    Cookies.set("role", res.data.msg.role);
                    navigate("/blog", {
                        state: { authToken },
                    });
                })
                .then(() => {
                    setLoading(false);
                });
        } catch (err) {
            setLoading(false);
            setMsg(err.response.data.err);
            setShow(true);

            setTimeout(() => {
                setMsg("");
                setShow(false);
            }, 5000);
        }
    };

    return (
        <motion.main
            className="main__container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
        >
            <div
                sx={{
                    padding: "2rem",
                }}
            >
                <div>
                    <div style={{ marginBottom: "10px" }}>
                        <Label htmlFor="username">Username</Label>
                        <Input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            name="username"
                            id="username"
                            mb={1}
                        />
                        {!validator.isEmail(email) && (
                            <span
                                sx={{
                                    color: "#fff",
                                    background: "primary",
                                    padding: "5px",
                                }}
                            >
                                Email address is not valid
                            </span>
                        )}
                    </div>
                    <Label htmlFor="password">Password</Label>
                    <Input
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type={showPassword ? "password" : "text"}
                        name="password"
                        id="password"
                        mb={3}
                    />
                    <Box>
                        <Label mb={3}>
                            <Checkbox
                                value={showPassword}
                                onChange={(e) => {
                                    setShowPassword(!showPassword);
                                }}
                            />
                            <span
                                sx={{
                                    userSelect: "none",
                                    margin: 0,
                                    padding: 0,
                                }}
                            >
                                Show Password
                            </span>
                        </Label>
                    </Box>

                    {show && (
                        <Message
                            sx={{
                                marginBottom: 3,
                            }}
                        >
                            {msg}
                        </Message>
                    )}

                    <Button
                        onClick={login}
                        className="fw"
                        sx={{
                            "&:hover": { background: "primary" },
                        }}
                    >
                        {!loading ? (
                            <>Login</>
                        ) : (
                            <div
                                className="full-screen"
                                sx={{
                                    background: "primary",
                                }}
                            >
                                <Spinner sx={{ color: "secondary" }} />
                            </div>
                        )}
                    </Button>
                </div>
            </div>
        </motion.main>
    );
};

export default Index;
