/** @jsxImportSource theme-ui */

import React, { useState } from "react";
import { motion } from "framer-motion";
import {
    Box,
    Button,
    Checkbox,
    Input,
    Label,
    Message,
    Spinner,
} from "theme-ui";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import validator from "validator";

const Index = () => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [repPassword, setRepPassword] = useState("");
    const [msg, setMsg] = useState("");
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(true);
    const [show, setShow] = useState(false);

    const navigate = useNavigate();

    const register = async () => {
        setLoading(true);
        try {
            const res = await axios
                .post(`${process.env.REACT_APP_SERVER}/register`, {
                    first_name: firstName,
                    last_name: lastName,
                    email,
                    password,
                })
                .then((res) => {
                    console.log(res);
                    let authToken = res.data.msg.token;

                    Cookies.set("token", authToken);
                    Cookies.set("_id", res.data.msg._id);
                    navigate("/login");
                })
                .then(() => setLoading(false));
        } catch (err) {
            setMsg(err.response.data.err);
            setShow(true);
            setLoading(false);

            setTimeout(() => {
                setMsg(err.response.data.err);
                setShow(false);
            }, 5000);
        }
    };

    return (
        <motion.main
            className="main__container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
        >
            <div
                sx={{
                    padding: "2rem",
                }}
            >
                <Label htmlFor="first_name">First Name</Label>
                <Input
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    name="first_name"
                    id="first_name"
                    mb={3}
                />
                <Label htmlFor="last_name">Last Name</Label>
                <Input
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    name="last_name"
                    id="last_name"
                    mb={3}
                />
                <div style={{ marginBottom: "10px" }}>
                    <Label htmlFor="phone">Phone Number</Label>
                    <Input
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        name="phone"
                        id="phone"
                        mb={1}
                    />
                    {!validator.isMobilePhone(phone, "en-IN") && (
                        <span
                            sx={{
                                color: "#fff",
                                background: "primary",
                                padding: "5px",
                            }}
                        >
                            Phone number is not valid Indian number
                        </span>
                    )}
                </div>
                <div style={{ marginBottom: "10px" }}>
                    <Label htmlFor="email">Email</Label>
                    <Input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        name="email"
                        id="email"
                        mb={1}
                    />
                    {!validator.isEmail(email) && (
                        <span
                            sx={{
                                color: "#fff",
                                background: "primary",
                                padding: "5px",
                            }}
                        >
                            Email address is not valid
                        </span>
                    )}
                </div>
                <div style={{ marginBottom: "10px" }}>
                    <Label htmlFor="password">Password</Label>
                    <Input
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type={showPassword ? "password" : "text"}
                        name="password"
                        id="password"
                        mb={1}
                    />
                    <Label htmlFor="repPassword">Repeat Password</Label>
                    <Input
                        value={repPassword}
                        onChange={(e) => setRepPassword(e.target.value)}
                        type={showPassword ? "password" : "text"}
                        name="repPassword"
                        id="repPassword"
                        mb={1}
                    />
                    {!(password === repPassword) && (
                        <span
                            sx={{
                                color: "#fff",
                                background: "primary",
                                padding: "5px",
                            }}
                        >
                            Passwords don't match
                        </span>
                    )}
                </div>
                <Box>
                    <Label>
                        <Checkbox
                            value={showPassword}
                            onChange={(e) => {
                                setShowPassword(!showPassword);
                            }}
                        />
                        <span
                            sx={{
                                userSelect: "none",
                                margin: 0,
                                padding: 0,
                            }}
                        >
                            Show Password
                        </span>
                    </Label>
                </Box>

                {show && (
                    <Message
                        sx={{
                            marginTop: "10px",
                            background: "primary",
                            textAlign: "center",
                            color: "#fff",
                        }}
                    >
                        {msg}
                    </Message>
                )}
                <Button
                    sx={{ marginTop: "10px" }}
                    onClick={register}
                    className="fw"
                >
                    {loading ? (
                        <>Register</>
                    ) : (
                        <div className="full-screen">
                            <Spinner sx={{ color: "secondary" }} />
                        </div>
                    )}
                </Button>
            </div>
        </motion.main>
    );
};

export default Index;
