/** @jsxImportSource theme-ui */

import React, { useState } from 'react';
import { Button, Container, Divider, Grid } from 'theme-ui';
import { IoTimeOutline } from 'react-icons/io5';
import Modal from '../../shared/Modal';
import { motion } from 'framer-motion';

const Programs = () => {
	const [modalTitle, setModalTitle] = useState('');
	const [modalDesc, setModalDesc] = useState('');
	const [modalIsOpen, setModalIsOpen] = useState(false);

	const onClose = () => {
		setModalIsOpen(false);
	};

	const summerIntern = [
		{
			name: 'Get started with web technologies',
			skills: [
				'Hypertext Markup Language (HTML)',
				'Cascading Style Sheets (CSS)',
				'Java Script (JS)',
			],
			duration: '6 weeks (2 classes a week)',
			difficulty: 'Beginner',
			brief:
				'<p>A summer internship on web technologies like HTML, CSS, and JS can be a great way to learn the basics of web development and gain experience working on real-world projects. During an internship, you will typically be assigned to a team of developers and work on a specific project. You will learn how to use HTML, CSS, and JS to create and style web pages, and you will also learn about other aspects of web development, such as web servers, databases, and security.</p>' +
				'<p>In addition to learning the technical skills, you will also have the opportunity to learn from experienced developers and gain insights into the web development industry. You will also have the chance to network with other developers and make connections that could help you in your future career.</p>' +
				'<ul>' +
				'<li>Learn the basics of web development</li>' +
				'<li>Gain experience working on real-world projects</li>' +
				'<li>Learn from experienced developers</li>' +
				'</ul>',
		},
		{
			name: 'Taming python',
			skills: ['Python'],
			duration: '6 weeks (2 classes a week)',
			difficulty: 'Beginner',
			brief:
				"<p>A summer internship on basic Python is a great way to learn the basics of this popular programming language. During the internship, you will learn about Python's syntax, data types, variables, functions, loops, and conditional statements. You will also have the opportunity to work on real-world projects, which will help you to solidify your skills.</p>" +
				'<p>Here is a brief summary of what you can expect from a summer internship on basic Python:</p>' +
				'<ul>' +
				'<li>Learn the basics of Python syntax and data types</li>' +
				'<li>Work on real-world projects that will help you to solidify your skills</li>' +
				'<li>Gain experience working in a team environment</li>' +
				'</ul>',
		},
	];

	return (
		<motion.main
			className='main__container'
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.5 }}
		>
			<Container p={1}>
				<h1
					className='tac'
					sx={{ color: 'primary' }}
				>
					Summer Internships
				</h1>

				<Modal
					title={modalTitle}
					isOpen={modalIsOpen}
					desc={modalDesc}
					onClose={onClose}
				/>
				<section className={'ph-5'}>
					<Grid columns={1}>
						{summerIntern.map((item) => (
							<div className={'card'}>
								<h5
									className='tac'
									sx={{ color: 'primary' }}
								>
									{item.name}
									<br />({item.difficulty})
								</h5>
								<Divider />
								<p className='tac'>
									<IoTimeOutline />
									&nbsp;{item.duration}
								</p>
								<p className={'tac'}>
									{item.skills.join(' | ')}
								</p>
								<Divider />
								<p
									dangerouslySetInnerHTML={{
										__html: item.brief,
									}}
								></p>
								<Button
									onClick={() => {
										setModalIsOpen(true);
										setModalTitle(item.name);
										setModalDesc(item.brief);
									}}
									className={'fw'}
									variant={'primary'}
									disabled
								>
									Summer internships will begin next summer!
								</Button>
							</div>
						))}
					</Grid>
				</section>
			</Container>
		</motion.main>
	);
};
export default Programs;
